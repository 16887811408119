import React from "react"
import { graphql } from 'gatsby'
import { TypographyStyle, GoogleFont } from 'react-typography'
import Layout from "../components/layout"
import Card from "../components/card"
/* import Image from "../components/image" */
import SEO from "../components/seo"
import typography from '../utils/typography'

const AuthorPage = ({data, pageContext}) => {
  const posts = data.allContentfulBlogPost.edges

  return (
    <>
      <head>
        <TypographyStyle typography={typography} />
        <GoogleFont typography={typography} />
      </head>
      <Layout>
        <SEO title={pageContext.author} />
        <div className="container -ml-4 pl-4 lg:mt-20">
          <h3 className="ml-4 pt-4">{pageContext.author}</h3>
          <div className="content-start flex flex-wrap justify-start">
            {posts.map(({node: post, index}) => (
              <Card key={post.id} {...post} />
            ))}
          </div>
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query ($author: String!) {
    allContentfulBlogPost (filter: {node_locale: {eq: "en-US"}, author: {name: {eq: $author}}}){
      edges {
        node {
          id
          title
          slug
          author {
            name
          }
          body {
            body
          }
          tags
          category
          createdAt
          description {
            description
          }
          publishDate(formatString:"DD MMMM, YYYY")
          heroImage {
            file {
              url
            }
          }
        }
      }
    }
  }
`
export default AuthorPage
